import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Footer from "../components/footer"
import { EnteringView, LeavingView, EnteringSmall, LeavingSmall, EnteringHide, LeavingHide } from "../components/cursor"

const ContactPage = ({ data }) => {
    const page = data.contact
    return (
        <Layout>
            <HelmetDatoCms 
                seo={page.seoMetaTags}
                bodyAttributes={{
                    class: 'light-bg secondary-page contact-page'
                }}
            />
            <main key="main" class={`background-${page.background}`}>
                <section>
                    <h5>{page.subtitle}</h5>
                    <h1>{page.title}</h1>
                </section>
                <section className="contact">
                    <div className="emails">
                        <h5>{page.emailsTitle}</h5>
                        {page.content.map(email => (
                            <div className="email-item">
                                <p><strong>{email.name}</strong></p>
                                <p><a href={`mailto: ${email.email}`}>{email.email}</a></p>
                            </div>
                        ))}
                    </div>
                    <div className="map" onMouseEnter={EnteringHide} onMouseLeave={LeavingHide}>
                        <iframe
                            className="map-iframe"
                            title="Google Map"
                            width=""
                            height=""
                            loading="lazy"
                            allowfullscreen
                            referrerpolicy="no-referrer-when-downgrade"
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAszn3PgxkTfSW6R6hlaU11Pj94xhHEB3k
                                &q=Soursop+Amsterdam">
                        </iframe>
                    </div>

                </section>
            </main>
            <Footer color='blue'></Footer>
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
query {
contact: datoCmsContact {
    background
    subtitle
    title
    googleMaps
    emailsTitle
    content {
        email
        name
    }
    seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
    }
}
}
`