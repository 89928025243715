import * as React from 'react'
import LogoMark from '../svg/mark'
import Logo from '../svg/logo'
import {Link} from 'gatsby'
import FooterData from '../data/footer'

const Footer = ({ color }) => {
    const data = FooterData();
    const footer = data.footer;
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <footer className={`page-footer color-${color}`}>
            <div className='footer-blurb'><h2>{footer.blurb}</h2></div>
            <div className='footer-columns'>
                <div className='footer-contact footer-column'>
                    <div className='subnavigation'>{footer.contactColumnTitle}</div>
                    {footer.contactLinks.map(link => (
                        <a className='link-item large-link-item' target="_blank" rel="norefferer" href={`mailto: ${link.url}`}>
                            <h5>{link.title} →</h5>
                        </a>
                    ))}
                </div>
                <div className='footer-social footer-column'>
                    <div className='subnavigation'>{footer.socialColumnTitle}</div>
                    {footer.socialLinks.map(link => (
                        <a className='link-item large-link-item' target="_blank" rel="norefferer" href={`${link.url}`}>
                            <h5>{link.title} →</h5>
                        </a>
                    ))}
                </div>
                <div className='footer-location footer-column'>
                    {footer.locations.map(location => (
                        <>
                            <div className='subnavigation'>{location.title}</div>
                            <a className='link-item large-link-item' target="_blank" rel="norefferer" href={`${location.url}`}>
                                <h5>{location.text} →</h5>
                            </a>
                        </>
                    ))}
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='copyright'>© Copyright {data.datoCmsGlobal.siteName} {getCurrentYear()}</div>
                <div className='policy-links'>
                    {footer.policies.map(policy => (
                        <a className="policy-link" href={policy.slug}>{policy.title}</a>
                    ))}
                </div>
            </div>
        </footer>
    )
}

export default Footer