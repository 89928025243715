import { useStaticQuery, graphql } from "gatsby";

const FooterData = () => useStaticQuery(graphql`
    query FooterDataQuery {
        footer: datoCmsFooter {
            blurb
            contactColumnTitle
            contactLinks {
                title
                url
                linkType
            }
            socialColumnTitle
            socialLinks {
                title
                url
            }
            locations {
                title
                url
                text
            }
            policies {
                slug
                title
            }
        }
        datoCmsGlobal {
            siteName
        }
    }
`);

export default FooterData;